import React from 'react';
import { graphql } from 'gatsby';
import { Query, Craft_PagesPage } from '~/query';

import CraftPageBuilder from '~/components/craft/CraftPageBuilder';
import PageHeaderSpacing from '~/components/common/PageHeaderSpacing';
import PageHeader from '~/components/common/PageHeader';
import Seo from '~/components/common/Seo';

type TemplatePageProps = {
  data: Query;
};

/**
 * Template: Pages
 *
 * Use this template in correspondence with a craft
 * section with the following properties:
 *
 * Type: structure
 * Name: pages
 * URI: {parent.uri}/{slug}
 *
 * And an entry type with the following properties:
 * Name: page
 */
export default function TemplatePages({ data }: TemplatePageProps) {
  const page = (data?.craft?.entry ?? {}) as Craft_PagesPage;
  const { title, seoTitle, seoDescription, seoCanonical } = page;

  const pageHeaderEnabled = Boolean(page.pageHeaderEnabled) ?? undefined;
  const pageHeaderImage =
    (page.pageHeaderImage && page.pageHeaderImage[0]) ?? undefined;

  return (
    <>
      <Seo
        title={seoTitle ?? title}
        description={seoDescription ?? undefined}
        canonical={seoCanonical ?? undefined}
      />
      {pageHeaderEnabled ? (
        <PageHeader backgroundImage={pageHeaderImage ?? undefined} />
      ) : (
        <PageHeaderSpacing />
      )}
      <CraftPageBuilder components={page.pageBuilder} />
    </>
  );
}

export const query = graphql`
  query QueryPage($id: [Int]!, $site: String!) {
    craft {
      entry(id: $id, site: $site) {
        id
        title
        ... on Craft_PagesPage {
          pageHeaderEnabled
          pageHeaderImage {
            ...CraftImageFluid
          }
          pageBuilder {
            ...CraftPageBuilderQuery
          }

          # SEO
          seoTitle
          seoDescription
          seoCanonical
        }
      }
    }
  }
`;
